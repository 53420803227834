import * as React from 'react';
import * as styles from './terms.module.scss';

import Callout from '../components/v2/callout';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import SEO from '../components/seo';
import Section from '../components/section';

const SubprocessorsPage = () => {
  return (
    <>
      <SEO
        title="Subprocessors"
        description="Discover the trusted third-party subprocessors that enhance our SaaS platform's functionality. Transparency and data security are our top priorities."
      />
      <Header btnTheme="primary" />
      <main className={styles.terms}>
        <Section theme="white" style={{ marginTop: `4.5rem` }}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="heading-xl color-black">Subprocessors</h1>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ paddingTop: '0' }}>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <p className="heading-lg color-black">Third-Party Subprocessors</p>
              <table className="text-body color-black pd-top-2">
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Purpose</th>
                    <th>Website</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Intercom</th>
                    <td>Live Chat, Customer Management</td>
                    <td>https://www.intercom.com/</td>
                  </tr>
                  <tr>
                    <th scope="row">Notion</th>
                    <td>Customer Support Management</td>
                    <td>https://www.notion.so/</td>
                  </tr>
                  <tr>
                    <th scope="row">HubSpot</th>
                    <td>Live Chat, Customer Management, Email Management</td>
                    <td>https://www.hubspot.com/</td>
                  </tr>
                  <tr>
                    <th scope="row">Mailchimp</th>
                    <td>Transactional Email</td>
                    <td>https://mailchimp.com/</td>
                  </tr>
                  <tr>
                    <th scope="row">Appcues</th>
                    <td>Customer Success</td>
                    <td>https://www.appcues.com/</td>
                  </tr>
                  <tr>
                    <th scope="row">Crossbeam</th>
                    <td>Partnership Management</td>
                    <td>https://www.crossbeam.com/</td>
                  </tr>
                  <tr>
                    <th scope="row">Calendly</th>
                    <td>Appointment Scheduling</td>
                    <td>https://calendly.com/</td>
                  </tr>
                  <tr>
                    <th scope="row">Zapier</th>
                    <td>Workflow Automation</td>
                    <td>https://zapier.com/</td>
                  </tr>
                  <tr>
                    <th scope="row">Stripe</th>
                    <td>Payment Processor, Subscription Management</td>
                    <td>https://stripe.com/</td>
                  </tr>
                  <tr>
                    <th scope="row">PartnerStack</th>
                    <td>Affiliate Management</td>
                    <td>https://www.partnerstack.com/</td>
                  </tr>
                  <tr>
                    <th scope="row">Google reCAPTCHA</th>
                    <td>Spam Prevention</td>
                    <td>https://www.google.com/recaptcha/about/</td>
                  </tr>
                  <tr>
                    <th scope="row">AWS</th>
                    <td>Data Storage</td>
                    <td>https://aws.amazon.com/</td>
                  </tr>
                  <tr>
                    <th scope="row">MongoDB</th>
                    <td>Data Storage</td>
                    <td>https://www.mongodb.com/</td>
                  </tr>
                  <tr>
                    <th scope="row">Redis Labs</th>
                    <td>Data Storage</td>
                    <td>https://redislabs.com/</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Section>
        <Callout />
      </main>
      <Footer />
    </>
  );
};

export default SubprocessorsPage;
